import { NMAAHCPropTypes, Theme } from "assets";
import { FormattedText, Tag } from "atoms";
import classNames from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./card.module.scss";

const Card = ({
  byline,
  caption,
  externalLink,
  horizontalLayout,
  image,
  imageObjectFit,
  imageObjectPosition,
  pageLink,
  showTags,
  status,
  storyType,
  subtitle,
  tags,
  theme,
  title,
}) => {
  const className = classNames(styles.card, {
    [styles.light]: theme === Theme.Light,
    [styles.white]: theme === Theme.White,
    ...Theme.addClass(theme, true),
    [styles.storyCard]: storyType,
    [styles.horizontalLayout]: horizontalLayout,
  });

  const statusClassName = classNames(styles.status, {
    [styles.explored]: status === "explored",
  });

  const statusIconMap = {
    explored: "inactive",
    unexplored: "progress",
  };

  if (!pageLink && !externalLink) {
    return (
      <div className={className}>
        <p
          className={classNames(styles.title, styles.errorText)}
          data-testid={"errorTitle"}
        >
          Error: You must provide either a link to another page or a link to an
          external page.
        </p>
      </div>
    );
  }

  let CardLink = Link;
  let cardLinkProps = {};
  if (!pageLink) {
    CardLink = "a";
    cardLinkProps.href = externalLink;
    cardLinkProps.target = "_blank";
  } else {
    cardLinkProps.key = pageLink.id;
    cardLinkProps.to = `/${pageLink.uri}`;
  }

  const isPortrait =
    image?.imageFile?.childImageSharp?.gatsbyImageData?.height >
    image?.imageFile?.childImageSharp?.gatsbyImageData?.width;
  const imageClass = classNames({
    [styles.portraitImage]: isPortrait,
    [styles.landscapeImage]: !isPortrait,
  });

  if (storyType) {
    return (
      <div className={className} data-testid="card">
        <div className={styles.storyCardImage}>
          {image && (
            <GatsbyImage
              alt={image.altText}
              formats={["auto", "webp", "avif"]}
              image={getImage(image.imageFile)}
              objectFit={imageObjectFit}
              objectPosition={imageObjectPosition}
            />
          )}
        </div>
        <div className={styles.cardContent}>
          <div className={`${styles.storyType} large-font`}>{storyType}</div>
          <h3 className={styles.title}>
            <Link to={`/${pageLink.uri}`}>{title}</Link>
          </h3>
          <div className={styles.caption}>
            <FormattedText
              className={classNames(Theme.addClass(Theme.Black, false))}
              text={caption}
            />
          </div>
          <div className={`${styles.readMore} large-font`}>
            <Link to={`/${pageLink.uri}`}>
              Read More
              <i className={"icon-arrow-forward"} role="presentation" />
            </Link>
          </div>
          {tags && (
            <ul
              className={classNames(styles.tags, {
                "visually-hidden": !showTags,
              })}
            >
              {tags?.map((tag, i) => tag && <Tag key={i} text={tag} />)}
            </ul>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={className} data-testid="card">
        {externalLink && (
          <div className={styles.externalLinkIconContainer}>
            <i
              className={classNames(
                "icon-external-link",
                styles.externalLinkIcon
              )}
            />
          </div>
        )}
        <div className={imageClass}>
          {image && (
            <GatsbyImage
              alt={image.altText}
              formats={["auto", "webp", "avif"]}
              image={getImage(image.imageFile)}
            />
          )}
        </div>
        <div className={styles.cardContent}>
          {status && (
            <div className={statusClassName}>
              <i className={`icon-${statusIconMap[status]}`} />
              {status}
            </div>
          )}
          <h3 className={styles.title}>
            <CardLink className={styles.titleLink} {...cardLinkProps}>
              {title}
            </CardLink>
          </h3>
          <p className={styles.subtitle}>{subtitle}</p>
          {byline && <p className={styles.byline}>{byline}</p>}
          <div className={styles.caption}>
            <p className={classNames(Theme.addClass(Theme.Subtle, false))}>
              {caption}
            </p>
          </div>
        </div>
      </div>
    );
  }
};

Card.propTypes = {
  byline: PropTypes.string,
  caption: PropTypes.string,
  externalLink: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  image: NMAAHCPropTypes.Image,
  imageObjectFit: PropTypes.oneOf(["contain", "cover", "none", "scale-down"]),
  imageObjectPosition: PropTypes.string,
  pageLink: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  showTags: PropTypes.bool,
  status: PropTypes.oneOf(["explored", "unexplored"]),
  storyType: PropTypes.string,
  subtitle: PropTypes.string,
  tags: PropTypes.array,
  theme: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Card.defaultProps = {
  horizontalLayout: false,
};

export default Card;
