import { flattenImageFields, NMAAHCPropTypes } from "assets";
import {
  ActionButton,
  ConstellationButton,
  ConstellationGraphic,
  ConstellationLoop,
  FormattedText,
} from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { DirectionNavigation, ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

import { sortConstellationTimeline } from "../constellation-page/constellation-page";
import * as styles from "./constellation-group.module.scss";

/** Maximum number of constellation objects per column */
const MAX_OBJECTS_COLUMN = 3;

const typeMap = {
  CraftAPI_constellationComponentList_entry_BlockType: "entry",
  CraftAPI_constellationComponentList_externalLink_BlockType: "externalLink",
  CraftAPI_constellationComponentList_image_BlockType: "image",
  CraftAPI_constellationComponentList_quotePromo_BlockType: "quotePromo",
  CraftAPI_constellationComponentList_video_BlockType: "video",
};

const ConstellationGroup = ({
  btnText,
  children,
  constellationComponentList,
  description,
  descriptionHeader,
  isChildObject,
  mainImage,
  nextConstellation,
  objects,
  parent,
  reference,
  tag,
  title,
  date,
}) => {
  // Pull the data from the lowest level original content
  const referencePage = reference?.[0];

  const mapChild = (child, i, numColumnChildren) => {
    const isActiveChild = title === child.title;
    const className = classNames({
      [styles.activeChild]: isActiveChild,
      [styles.outdent]:
        numColumnChildren &&
        numColumnChildren === MAX_OBJECTS_COLUMN &&
        i === Math.floor(numColumnChildren / 2),
    });

    const type = child?.constellationComponentList?.[0]?.__typename;

    return (
      <div className={className} key={child.id}>
        <ConstellationButton
          buttonImagePosition={child.buttonImagePosition}
          image={child.coverImage?.[0]}
          showIcon={!isActiveChild}
          tag={child.tag}
          title={child.title}
          to={`/${child.uri}`}
          type={typeMap[type] || "image"}
          small
        />
      </div>
    );
  };

  const mainObjectBtn = (classname) => (
    <div className={`${styles.mainObjectThumbnail} ${classname}`}>
      <svg
        className={styles.constellationStar}
        viewBox="0 50 2500 250"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g data-testid="constellation-arm" transform={"translate(1000, 0)"}>
          <circle cx="250" cy="500" fill="#090307" r="24" />
          <g transform={"translate(165, 250) scale(-4, 4) rotate(180)"}>
            <g>
              <animate
                attributeName="opacity"
                begin="0s"
                dur="2s"
                repeatCount="indefinite"
                values="1.0;0.4;1.0"
              />
              <path
                d="M10 3.26475L11.749 8.70026L11.8889 9.1352L12.3093 9.31411L17.4461 11.5L12.3093 13.6859L11.8889 13.8648L11.749 14.2997L10 19.7352L8.25103 14.2997L8.11108 13.8648L7.69065 13.6859L2.55389 11.5L7.69065 9.31411L8.11108 9.1352L8.25103 8.70026L10 3.26475Z"
                stroke="#FFAE18"
                strokeWidth="2"
              />
            </g>
            <g>
              <animate
                attributeName="opacity"
                begin=".75s"
                dur="2s"
                repeatCount="indefinite"
                values="1.0;0.4;1.0"
              />
              <path
                d="M22.5 26.4732L23.2979 29.1272L23.4285 29.5614L23.8411 29.7493L26.5873 31L23.8411 32.2507L23.4285 32.4386L23.2979 32.8728L22.5 35.5268L21.7021 32.8728L21.5715 32.4386L21.1589 32.2507L18.4127 31L21.1589 29.7493L21.5715 29.5614L21.7021 29.1272L22.5 26.4732Z"
                stroke="#FFAE18"
                strokeWidth="2"
              />
            </g>
            <g>
              <animate
                attributeName="opacity"
                begin="1.5s"
                dur="2s"
                repeatCount="indefinite"
                values="1.0;0.4;1.0"
              />
              <path
                d="M30.5 12.1649L30.7666 12.9655L30.9114 13.4002L31.3351 13.5744L32.3702 14L31.3351 14.4256L30.9114 14.5998L30.7666 15.0345L30.5 15.8351L30.2334 15.0345L30.0886 14.5998L29.6649 14.4256L28.6298 14L29.6649 13.5744L30.0886 13.4002L30.2334 12.9655L30.5 12.1649Z"
                stroke="#FFAE18"
                strokeWidth="2"
              />
            </g>
          </g>
        </g>
      </svg>
      <ConstellationButton
        image={parent.constellationImage?.[0]}
        tag={parent.tag}
        title={parent.title}
        to={`/${parent.uri}`}
        small
      />
    </div>
  );

  const evenChildren = objects?.filter((obj, i) => i % 2 === 0);
  const oddChildren = objects?.filter((obj, i) => i % 2 === 1);

  const actionButtonText = btnText || `Dive into ${referencePage?.title}`;
  const type = constellationComponentList?.[0]?.__typename;

  return (
    <>
      <div className="container-fluid">
        <div
          className={classNames("row", styles.page)}
          data-testid="constellation-group"
        >
          <ConstellationGraphic
            className={styles.constellationGraphic}
            numArms={objects?.length || 0}
          />
          <div
            className={`hidden-mobile hidden-tablet col-md-3 ${styles.objectColumnOne}`}
            data-testid="even-objects"
          >
            {evenChildren.map((obj, i) =>
              mapChild(obj, i, evenChildren.length)
            )}
            {
              // Add empty divs as spacers
              [...Array(MAX_OBJECTS_COLUMN - evenChildren.length).keys()].map(
                (i) => (
                  <div key={`even-placeholder-${i}`} />
                )
              )
            }
          </div>
          <div
            className="col-xs col-md-offset-0 col-md-6"
            style={{ zIndex: 2 }}
          >
            <p
              className={styles.helper}
              data-testid="constellation-group-helper"
            >
              Explore the Constellation
            </p>
            <h1 data-testid="constellation-group-title">{title}</h1>
            {tag && (
              <p className={styles.tag} data-testid="constellation-group-tag">
                {tag}
              </p>
            )}
            {mainImage?.imageFile &&
              !["video", "quotePromo"].includes(typeMap[type]) && (
              <div className={styles.imgWrapper}>
                <ExpandableImage image={mainImage} modalCaptionOnly />
              </div>
            )}
            {children}
            <div className={styles.descriptionContainer}>
              {date && <p className={styles.date}>{date}</p>}
              <FormattedText
                className={styles.descriptionHeader}
                text={descriptionHeader}
              />
              <FormattedText
                className={styles.description}
                text={description}
              />
              <Helmet>
                <meta content={description} name="description" />
              </Helmet>
            </div>
            {isChildObject && mainObjectBtn("hidden-mobile hidden-tablet")}
            {referencePage?.uri && (
              <div className={styles.actionButton}>
                <ActionButton
                  text={actionButtonText}
                  to={`/${referencePage.uri}`}
                />
              </div>
            )}
          </div>
          <div
            className={`hidden-mobile hidden-tablet col-md-3 ${styles.objectColumnTwo}`}
            data-testid="odd-objects"
          >
            {oddChildren.map((obj, i) => mapChild(obj, i, oddChildren.length))}
            {
              // Add empty divs as spacers
              [...Array(MAX_OBJECTS_COLUMN - oddChildren.length).keys()].map(
                (i) => (
                  <div key={`odd-placeholder-${i}`} />
                )
              )
            }
          </div>
          <div
            className={`hidden-desktop row col-xs-12 ${styles.objectRow}`}
            data-testid="all-objects"
          >
            {objects?.map((obj) => (
              <div className="col-xs-6" key={obj.id}>
                {mapChild(obj)}
              </div>
            ))}
            <ConstellationLoop
              className={styles.constellationLoop}
              numCircles={objects?.length || 0}
            />
          </div>
          {isChildObject && mainObjectBtn("hidden-desktop")}
        </div>
        <div className={styles.directionalNavMargins}>
          <DirectionNavigation
            nextTarget={nextConstellation ? nextConstellation : null}
            previousTarget={{
              id: "111111111",
              title: "All Constellations",
              uri: "constellations",
            }}
          />
        </div>
      </div>
    </>
  );
};

ConstellationGroup.propTypes = {
  btnText: PropTypes.string,
  children: PropTypes.node,
  constellationComponentList: PropTypes.object,
  date: PropTypes.string,
  description: PropTypes.string,
  descriptionHeader: PropTypes.string,
  isChildObject: PropTypes.bool,
  mainImage: NMAAHCPropTypes.Image,
  nextConstellation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }),
  objects: PropTypes.arrayOf(PropTypes.shape({})),
  parent: PropTypes.shape({
    constellationImage: NMAAHCPropTypes.Image,
    tag: PropTypes.string,
    title: PropTypes.string,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  reference: PropTypes.arrayOf(
    PropTypes.shape({
      shortDescription: PropTypes.string,
    })
  ),
  tag: PropTypes.string,
  title: PropTypes.string,
};

ConstellationGroup.defaultProps = {
  isChildObject: false,
  objects: [],
};

const ConstellationChildObjectsFragment = graphql`
  fragment ConstellationChildObjectsFragment on CraftAPI_constellations_constellationObject_Entry {
    coverImage {
      ...ImageMetadataFragment
    }
    tag(label: true)
    title
    uri
    buttonImagePosition
    constellationComponentList {
      ... on CraftAPI_constellationComponentList_MatrixField {
        __typename
      }
    }
  }
`;

/**
 * Converts the provided constellation group data into an active group
 *
 * @param constellationGroupData the GraphQL response data
 * @returns                      the constellation group modal
 */
const convert = (constellationGroupData, isChildObject) => {
  const timelineSortedGroups = sortConstellationTimeline(
    isChildObject
      ? constellationGroupData.parent.parent.children
      : constellationGroupData.parent.children
  );
  const currentIndex = timelineSortedGroups.findIndex(
    (g) =>
      g.id ===
      (isChildObject
        ? constellationGroupData.parent.id
        : constellationGroupData.id)
  );
  const nextConstellation =
    currentIndex + 1 < timelineSortedGroups.length
      ? timelineSortedGroups[currentIndex + 1]
      : timelineSortedGroups[0];

  return (
    <ConstellationGroup
      key={constellationGroupData.id}
      {...flattenImageFields(constellationGroupData)}
      description={
        constellationGroupData?.text || constellationGroupData?.shortDescription
      }
      isChildObject={isChildObject}
      mainImage={constellationGroupData?.coverImage?.[0]}
      nextConstellation={nextConstellation}
    />
  );
};

export {
  ConstellationChildObjectsFragment,
  convert,
  ConstellationGroup as default,
};
