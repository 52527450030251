import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./tag.module.scss";

const Tag = ({ color, icon, text }) => {
  return (
    <p className={classNames(styles.tag, styles[color])}>
      {icon && <i className={icon} />}
      {text}
    </p>
  );
};

Tag.Colors = {
  Blue: "blue",
  Red: "red",
};

Tag.propTypes = {
  color: PropTypes.oneOf(Tag.Colors),
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default Tag;
